
/* Styles pour HeaderFooterdiv */
.HeaderFooterdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 200px) and (max-width: 700px)  {
    
  }
}

/* Styles pour CardConst */
.CardConst {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  
    @media screen and (min-width: 200px) and (max-width: 700px)  {
      gap: 20px;
    }
  }
  
  
  /* Styles pour Carddiv, Cardul, CardToLogement, Cardp, et Cardimg */
  .Carddiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%; 
    margin: 30px 0;
    background-color: #f6f6f6;
    border-radius: 25px;

    @media screen and (min-width: 700px) and (max-width: 1024px) {
      background-color: white;
      width: 700px;
      border-radius: 10px;
    }
  
    @media screen and (min-width: 200px) and (max-width: 700px) {
      background-color: white;
      width: 335px;
      border-radius: 10px;
    }
  }
  
  .Cardul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 50px auto;
    padding: 0;
    gap: 50px;

    @media screen and (min-width: 700px) and (max-width: 1024px) {
      width: 90%;
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
    }
  
    @media screen and (min-width: 200px) and (max-width: 700px) {
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
      margin: 0;
    }
  }
  
  .CardToLogement {
    width: 330px;
    height: 340px;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;


    @media screen and (min-width: 700px) and (max-width: 1024px) {
      width: 330px;
    height: 320px;
    }
  }
  
  .Cardp {
    display: inline;
    position: absolute;
    width: 120px;
    height: auto;
    bottom: 20px;
    left: 10px;
    color: white;
    z-index: 2;
    font-size: 18px;
    font-weight: 500;
  }
  
  .Cardimg {
    position: absolute;
    width: 335px;
    height: 320px;
    z-index: 1;
    border-radius: 10px;
    object-fit: cover;
    filter: brightness(0.9);

    @media screen and (min-width: 700px) and (max-width: 1024px) {
      width: 330px;
      height: 320px;
    }

  
    @media screen and (min-width: 200px) and (max-width: 700px) {
      width: 335px;
      height: 320px;
    }
  }
  