@import 'Couleurs.scss';

.carousel-container {
    position: relative;
    margin: auto;
    width: 1240px;
    height: 415px;
    overflow: hidden;
    border-radius: 20px;
  
    @media screen and (min-width: 700px) and (max-width: 1024px) {
      width: 700px;
      height: 355px;
    }

    @media screen and (min-width: 200px) and (max-width: 700px)  {
      width: 335px;
      height: 255px;
    }
  
    
  }
  
  .carousel-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  
    @media screen and (min-width: 700px) and (max-width: 1024px) {
      width: 700px;
      height: 355px;
    }

    @media screen and (min-width: 200px) and (max-width: 700px) {
      width: 335px;
      height: 255px;
    }
  }
  
  
  
  .carousel-buttons {
    position: absolute;
    top: 40%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  
    @media screen and (min-width: 700px) and (max-width: 1024px)  {
      width: 700px;
    }

    @media screen and (min-width: 200px) and (max-width: 700px)  {
      width: 335px;
    }
  
  }
  
  .carousel-button {
    background-color: transparent;
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;
  
    &:hover {
      opacity: 0.8;
    }
  
    img {
      width: 100px;
      height: 100px;
    }
  
    @media screen and (min-width: 700px) and (max-width: 1024px)  {
      img {
        width: 80px;
        height: 80px;
      }
    }

    @media screen and (min-width: 200px) and (max-width: 700px)  {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  
  .carousel-p {
    position: absolute;
    right: 50%;
    bottom: 0px;
    font-weight: 500;
    
    color: white;
    font-family: Montserrat;
    font-size:18px;
    

  
    @media screen and (min-width: 200px) and (max-width: 700px)  {
      position: absolute;
    right: 50%;
    bottom: 0px;
    font-weight: 500;
    color: white;
    font-family: Montserrat;
    font-size:18px;
    
    }
  }
  