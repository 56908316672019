@import 'Couleurs';
.HeaderFooterdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

}
.footer {
  width: 100%;
  //margin-top: 50px;
  margin-bottom: 0;
  padding:50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  &__logo {
    margin-top: 60px;
    filter: brightness(400%);
    width: 145px;
    height: 46px;
    margin-bottom: 20px;
  }
  &__text {
    color: white;
    font-size: 24px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 700px) and (max-width: 1024px) {
    margin: 0 auto;
   width:700x;
 
   
    &__text {
      margin-bottom: 30px;
      font-size: 18px;
      
    }
  }

  @media screen and (min-width: 200px) and (max-width: 700px)  {
    width: 375px;
    padding: 0;
    margin-top: 0;
   
    &__text {
      margin-bottom: 60px;
      margin-top: -10px;
      font-size: 12px;
      width: 100px;
    }
  }

  
}
