
  .Etoilesdiv{
    width: 196px;
    height: 36px;


    @media screen and (min-width: 200px) and (max-width: 700px)  {
      margin-top: 20px;
      
    }
  }
    
@import 'Couleurs.scss';
/* LogementC */
.Logement {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: 700px) and (max-width: 1024px)  {
      
      width: 700px;
    }
  
    @media screen and (min-width: 200px) and (max-width: 700px)  {
      
      width: 335px;
    }
  }
  
  /* LogementDiv */
  .LogementDiv {
    width: 100%;
    max-width: 1240px;
    color: #ff6060;
    gap: 50px;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 700px) and (max-width: 1024px) {
     // padding: 0;
      gap: 20px;
      width: 700px;
    }
  
    @media screen and (min-width: 200px) and (max-width: 700px)  {
      padding: 0;
      gap: 30px;
      width: 335px;
    }
  }

   /* LogementContener */
   .LogementInfo {
    width: 100%;
    display: flex;
    color: $primary-color;
    justify-content: space-between;
    font-family: Montserrat;
  
   &1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    font-family: Montserrat;
    &__titre {
      font-size: 36px;
      font-weight: 500;
    }
    &__location{
      font-size: 22px;
    }
    &__Tagdiv{
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;
    }
    &__Tag{
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: #ff6060;
      padding: 5px 40px;
      border-radius: 10px;
      font-family: Montserrat;
    }

   }
   &2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &__host {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
      &--name {
        font-size: 18px;
        width: 59px;
      }
      &--pic {
        margin-left: 30px;
        position: relative;
      }
      &--pic img {
        display: inline;
        width: 64px;
        height: 64px;
        border-radius: 50px;
        object-fit: cover;
      }
   }
   &__Etoiles {
      width: 100%;
      gap:10px;
    & .activestar {
      width: 26px;
      height: 26px;
      margin-left: 10px;
    }
    & .innactivestar {
      color: #E3E3E3;
      width: 26px;
      height: 26px;
      margin-left: 10px;
      
    }
  }
  }

  @media screen and (min-width: 700px) and (max-width: 1024px) {
    width: 700px;
    display: flex;
    color: $primary-color;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    &1 {
      display: flex;
      flex-direction: column;
      //justify-content: flex-start;
      width: 100%;
      font-family: Montserrat;
      &__titre {
        font-size: 26px;
        font-weight: 500;
      }
      &__location{
        font-size: 18px;
      }
      &__Tagdiv{
        display: flex;
        flex-direction: row;
        
        gap: 10px;
      }
      &__Tag{
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #ff6060;
        padding: 5px 20px;
        border-radius: 10px;
        font-family: Montserrat;
      }
  
     }
     &2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
     // align-items: flex-end;
  
      &__host {
        display: flex;
        //justify-content: flex-end;
        align-items: center;
        
        margin-bottom: 30px;
        &--name {
          font-size: 14px;
          width: 39px;
        }
        &--pic {
          margin-left: 30px;
          position: relative;
        }
        &--pic img {
          display: inline;
          width: 64px;
          height: 64px;
          border-radius: 50px;
          object-fit: cover;
        }
     }
     &__Etoiles {
        //width: 700px;
        gap:10px;
      & .activestar {
        width: 26px;
        height: 26px;
        margin-left: 10px;
      }
      & .innactivestar {
        color: #E3E3E3;
        width: 26px;
        height: 26px;
        margin-left: 10px;
        
      }
    }
    }
  }

  @media screen and (min-width: 200px) and (max-width: 700px) {
  flex-direction: column;
  justify-content: normal;
  width: 335px;
  &1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 335px;
    font-family: Montserrat;
    &__titre {
      font-size: 20px;
      font-weight: 500;
    }
    &__location{
      font-size: 16px;
    }
    &__Tagdiv{
      display: flex;
      flex-direction: row;
      width:335px ;
      gap: 10px;
    }
    &__Tag{
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: #ff6060;
      padding: 5px 20px;
      border-radius: 10px;
      font-size: 10px;
      font-family: Montserrat;
    }
  }

  &2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: normal;
    align-items: center;

    &__host {
      align-items: center;
        margin-bottom: 0;
      &--name {
        font-size: 12px;
          text-align: right;
      }
      &--pic {
        margin-left: 10px;
        position: relative;
      }
      &--pic img {
        display: inline;
        width: 32px;
        height: 32px;
        border-radius: 50px;
        object-fit: cover;
      }
   }
   &__Etoiles {
      width: 100%;
      
    & .activestar {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    & .innactivestar {
      margin-left: 5px;
      width: 13px;
      height: 13px;
      
      
    }
  }
  }
}
   }


   
  
  .LogementC {
    display: flex;
    flex-direction: row;
    width: 100%;
    
    justify-content: space-between;
  
    // Collapse
    .aboutelements {
      width: 45%;
      margin-right: 0;
      margin-left: 0;
  
      &__aboutelement {
        margin: 30px 0 0 0;
        width: 100%;
        box-sizing: border-box;
        position: relative;
      }
      & p {
        padding: 0;
        list-style: none;
        text-align: left;
      }
      & .bar {
        width: 100%;
        box-sizing: border-box;
      }
      & .foo {
        width: 100%;
        box-sizing: border-box;
      }
    }

    @media screen and (min-width: 700px) and (max-width: 1024px) {

      .LogementC {
        display: flex;
        flex-direction: row;
        width: 100%;
        
        //justify-content: space-between;
      }
      .aboutelements {
        width: 45%;
        margin-right: 0;
        margin-left: 0;
    
        &__aboutelement {
          margin: 1px 0 0 0;
          width: 100%;
          box-sizing: border-box;
          position: relative;
        }
        & p {
          padding: 0;
          list-style: none;
          text-align: left;
        }
        & .bar {
          width: 100%;
          box-sizing: border-box;
        }
        & .foo {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }


    @media screen and (min-width: 200px) and (max-width: 700px) {
      flex-direction: column;
     // margin-top: 10px;
  
      .aboutelements {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        margin: 0 auto 30px auto;
        &__aboutelement {
          margin: 0;
          padding: 7px 10px;
          p {
            font-size: 14px;
          }
          img {
            width: 15px;
          }
        }
      }
    }

    
  }
  
    
  