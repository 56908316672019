

$color-primary: #FF6060;
$font-family: Montserrat;

.DivErr {
  display: flex;
  flex-direction: column;
  width: 1440px;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-primary;
  gap: 100px;

  @media screen and (max-width: 1024px) {
    width: 900px;
    padding: 30px 0;
  }

  @media screen and (max-width: 600px) {
    width: 335px;
    padding: 30px 0;
  }
}

.Div404 {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $font-family;
  font-size: 300px;
  font-weight: 700;

  @media screen and (min-width: 700px) and (max-width: 1024px)  {
    font-size: 200px;
   
    padding: 0;
    margin: 0;
  }

  @media screen and (min-width: 200px) and (max-width: 700px)  {
    font-size: 150px;
   
    padding: 0;
    margin: 0;
  }
}

.Divh1 {
  color: $color-primary;
  font-family: $font-family;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;

  @media screen and (min-width: 200px) and (max-width: 700px) {
    font-size: 24px;
    padding: 0;
    margin: 0;
  }
}

.Retour {
  color: $color-primary;
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;

  @media screen and (min-width: 200px) and (max-width: 700px)  {
    font-size: 16px;
  }
}

.HeaderFooterdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  @media screen and (min-width: 200px) and (max-width: 700px)  {
    height: auto;
    gap: 40px;
  }
}
