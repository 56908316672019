.card-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0;
    background-color: #f6f6f6;
    border-radius: 25px;

    
  }
  
  .card-ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 50px auto;
    padding: 0;
    gap: 50px;
  }
  
  .card-to-logement {
    width: 340px;
    height: 340px; 
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

     
    @media screen and (min-width: 700px) and (max-width: 1024px)  {
      width: 340px;
      height: 340px;
      }

    
      @media screen and (min-width: 200px) and (max-width: 700px) {
    width: 335px;
    height: 255px;
    }
  }
  
  .card-p {
    display: inline;
    position: absolute;
    width: 300px;
    Height:52px;
    Top:268px;
    Left:20px;
    font-family: Montserrat;
    color: white;
    z-index: 2;
    font-size: 18px;
    line-height: 25.67px;
    font-weight: 500;

    

    @media screen and (min-width: 200px) and (max-width: 700px)  {
      width: 295px;
      Height: 52px;
      Top:188px;
      font-family: Montserrat;
      color: white;
      line-height: 25.67px;

    }
  }
  
  .card-img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 10px;
    object-fit: cover;
    filter: brightness(0.9);
  }

  @media screen and (min-width: 700px) and (max-width: 1024px)  {
    .card-ul {
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
    }
  
    .card-div {
      background-color: #f6f6f6;
      width: 700px;
      border-radius: 10px;
    }
  }
  
  
  
  @media screen and (min-width: 200px) and (max-width: 700px)  {
    .card-ul {
      grid-template-columns: repeat(1, 1fr);
      margin: 0;
    }
  
    .card-div {
      background-color: white;
      width: 340px;
      border-radius: 10px;
    }
  }
  