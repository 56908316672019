@import 'Couleurs';

.header {
    display: flex;
    width: 1240px;
    
    margin: 45px auto;
    &__logo {
      width: 210px;
      height: 68px;
    }
    &__liens {
      width: 100%;
      display: flex;
      flex-direction: end;
      align-items: center;
      justify-content: right;
    }
    &__liens__lien {
      color: $primary-color;
      text-decoration: none;
      margin-left: 57px;
      font-size: 24px;
      letter-spacing: 0em;
    }
    .active {
      text-decoration: underline;
    }

    @media screen and (min-width: 700px) and (max-width: 1024px) {
      margin: 25px auto;
      width:700px;
      align-items: center;
    justify-content: center;
      
    }

    @media screen and (min-width: 200px) and (max-width: 700px) {
      width:335px;
      align-items: center;
    justify-content: center;
      &__logo {
        width: 145px;
        height: 46px;
      }
      &__liens__lien {
        font-size: 12px;
        margin-left: 20px;
        text-transform: uppercase;
      }
    }
  }
  