@import 'Couleurs.scss';

.About {
  width:1240px;
  position: relative;
  margin: auto;
  background-color: white;


  @media screen and (min-width: 700px) and (max-width: 1024px){
    margin-bottom: 20px;
    max-width: 700px;
  }

  @media screen and (min-width: 200px) and (max-width: 700px)  {
    margin-bottom: 20px;
    width: 335px;
  }
}
.aboutbanner {
  margin: auto;
  height: 223px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url(../assets/BannerBg2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: 223px;
    border-radius: 25px;
  }

  @media screen and (min-width: 700px) and (max-width: 1024px)  {
    width: 700px;
    height: 111px;
    &:before {
      height: 111px;
      border-radius: 10px;
    }
  }


  @media screen and (min-width: 200px) and (max-width: 700px)  {
    height: 111px;
    &:before {
      height: 111px;
      border-radius: 10px;
    }
  }
}

.aboutelements {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 100%;

  &__aboutelement {
    display: flex;
    justify-content: space-between;
    width: 80%;

    align-items: center;
    margin: 30px auto 0 auto;
    background-color: $primary-color;
    color: white;
    padding: 10px 20px;
    font-size: 22px;
    border-radius: 5px;
    position: relative;
    z-index: 2;

    & p {
      margin: 0;
    }
    &--button {
      background-color: inherit;
      border: 0;
      transition: transform 1s ease;
      &:hover {
        cursor: pointer;
      }
    }
  }


  @media screen and (min-width: 700px) and (max-width: 1024px)  {
    width: 100%;
    font-size: 18px;
    margin: 30px auto;

    &__aboutelement {
      padding: 15px 18px;
      
      margin: 10px auto;
      &--button img {
        width: 15px;
      }
    }
  }

  @media screen and (min-width: 200px) and (max-width: 700px)  {
    font-size: 12px;
    margin: 20px auto;

    &__aboutelement {
      width: 90%;
      font-size: 13px;
      padding: 5px 8px;
     // margin: 0 auto;
      &--button img {
        width: 15px;
      }
    }
  }
}
.activebutton {
  transform: rotate(-180deg);
}

//* Collapse

.foo {
  position: relative;
  max-height: 0px;
  background-color: #f6f6f6;
  width: 80%;
  
  padding: 0 20px;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
  border-radius: 0 0 5px 5px;
}

.bar {
  position: relative;
  background-color: #f6f6f6;
  width: 80%;
  padding: 0 20px;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
  border-radius: 0 0 5px 5px;
}
.aboutelement__text {
  color: $primary-color;
  position: relative;
  transform: translateY(-150px);
  transition: transform 0.5s ease-in-out;
}
.visible {
  transform: translateY(0);
}
